/* For larger screens like laptops and desktops */
.downloadImage {
    height: 50px; /* The original height you specified */
    max-width: 100%; /* Ensures the image won't exceed its original size */
    display: block; /* Prevents any potential alignment issues */
    margin: auto; /* Centers the image on the screen */
  }
  
  /* For smaller screens like phones */
  @media only screen and (max-width: 600px) {
    .responsive-image {
      height: 24px; /* Allows the image to scale proportionally */
      max-width: 100%; /* Ensures the image fits within the phone screen */
    }
  }
  